import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import {OdvReferentApi} from "../../../services/api.service";
import { ref } from "yup";
import {GRC_SELECTED_COMPANY} from "../../../redux/types";

const OdvReferent = new OdvReferentApi();
const OdvReferentComponent = ({dispatch, mode, referent}) => {
    if ( referent !== undefined && referent.account !== undefined ) {
        referent.email = referent.account.acc_email;
        referent.phone = referent.account.acc_mobile;
    }
    const [loading, setLoading] = useState(false);
    const disabled = mode === 'read' || mode === 'delete';
    const addReferentInputs = [
        {
            name: 'username',
            required: 'campo obbligatorio',
            label: 'Username',
            placeholder: 'Inserisci nome',
            width: 12,
            margin: 'normal',
            disabled,
            validate: (value) => ((!value && (referentForm.watch('email') || referentForm.watch('ref_telefono'))) || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono'))) ? 'Nome richiesto' : true
        },
        {
            name: 'email',
            required: 'campo obbligatorio',
            label: 'Email',
            placeholder: 'Inserisci email',
            width: 12,
            margin: 'normal',
            type: 'email',
            value: 'ciao',
            disabled,
            validate: (value) => ((!value && (referentForm.watch('email') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono'))) ? 'Email richiesta' : true
        },
        {
            name: 'phone',
            label: 'Telefono',
            required: 'campo obbligatorio',
            placeholder: 'Inserisci telefono',
            width: 12,
            margin: 'normal',
            disabled,
            validate: (value) => (mode!=='delete' &&  ((!value && (referentForm.watch('ref_email') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono')))) ? 'Telefono richiesto' : true
        },
        {
            name: 'password',
            label: 'Password',
            required: 'campo obbligatorio',
            placeholder: 'Inserisci Password',
            width: 12,
            margin: 'normal',
            type: 'password',
            disabled,
            validate: (value) => ( (mode !=='delete' && mode !== 'update') && ((!value && (referentForm.watch('ref_email') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono')))) ? 'Password richiesta' : true
        },
        {
            name: 'password_confirmation',
            label: 'Conferma password',
            required: 'campo obbligatorio',
            placeholder: 'Conferma la password',
            width: 12,
            margin: 'normal',
            type: 'password',
            disabled,
            validate: (value) => ( (mode !=='delete' && mode !== 'update') && ((!value && (referentForm.watch('ref_email') || referentForm.watch('ref_referente')))  || (!value && !referentForm.watch('ref_email') && !referentForm.watch('ref_telefono')))) ? 'Password confermata richiesta' : true
        }
    ];
    const referentForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            ...referent,
            fun_uuid: referent.funzioni ? referent.funzioni.uuid : null
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onReferentSave = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await OdvReferent.storeRiskRef({
                        ...data,
                    });
                    dispatch(handleToast({
                        message: 'Referente creato'
                    }));
                    break;
                case 'update':
                    await OdvReferent.storeRiskRef({
                        id: referent.id,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Referente modificato'
                    }));
                    break;
                case 'delete':
                    await OdvReferent.deleteRiskRef(referent.id);
                    dispatch(handleToast({
                        message: 'Referente eliminato'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <FormContext {...referentForm}>
            <form onSubmit={referentForm.handleSubmit(onReferentSave)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addReferentInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

OdvReferentComponent.defaultProps = {
    referent: {}
};
OdvReferentComponent.propTypes = {
    referent: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(OdvReferentComponent);
