import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import AuthService from "../../../services/auth.service";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {SITE_MAP, ENV} from "../../../constant";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {ConfigApi} from "../../../services/api.service";
import {CircularProgress} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    menu: {
        width: 400,
        height: '100%',
        background: '#3A3C48'
    },
    scrollable: {
        flexGrow: 1,
        //paddingBottom: '150px!important',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px'
        },
        '&::-webkit-scrollbar-track': {
            background: '#3A3C48'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5f5f5',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'white'
        }
    },
    whiteText: {
        color: 'white'
    },
    dividerText: {
        color: 'white',
        background: '#3A3C48',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute',
        width: 'fit-content',
        padding: '0 20px'
    },
    whiteDivider: {
        backgroundColor: 'rgba(255, 255, 255, 0.12)',
        position: 'relative',
        margin: theme.spacing(4)
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: 'white'
    },
    expansionPanel: {
        background: 'transparent',
        color: 'white',
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        }
    },
    list: {
        width: '100%',
        '& .MuiListItem-root': {
            width: '100%',
            background: '#454752',
            borderBottom: '2px solid #3A3C48',
            borderTop: '2px solid #3A3C48',
        }
    },
    /*    lastSection: {
            bottom: '0',
            background: '#3A3C48',
            position: 'absolute',
            width: '100%'
        }*/
}));

const DrawerComponent = ({open, toggleDrawer, history}) => {
    const configApi = new ConfigApi();
    let MyHome = SITE_MAP;
    let myHomeFiltered = [];
    for(let i = 0; i<MyHome.length; i++) {
        if (MyHome[i].expansion.length > 0) {
            myHomeFiltered = [];
            for (let z = 0; z < MyHome[i].expansion.length; z++) {
                if (MyHome[i].expansion[z].permission !== undefined && localStorage.getItem('permessi_utente').includes(MyHome[i].expansion[z].permission)) {
                    //console.log("il permesso " + MyHome[i].expansion[z].permission + " è stato trovato");
                    //console.log('metto ' + MyHome[i].expansion[z]);
                    myHomeFiltered.push(MyHome[i].expansion[z]);
                } else {
                    //console.log("il permesso " + MyHome[i].expansion[z].permission + " non è stato trovato");
                }
            }
            MyHome[i].expansion = myHomeFiltered;
        }
    }

    const [logo, setLogo] = React.useState(false);

    useEffect(() => {
        const loadSetup = async () => {
            const setupData = await configApi.setup();
            if ( setupData && setupData.logo && setupData.logo !== '' ) {
                setLogo(setupData.logo);
            }
        };
        loadSetup();
    }, []);

    if ( localStorage.getItem('permessi_utente').includes('risk.referente') ) {

        MyHome = [];

    }
    const classes = useStyles();
    const [expanded, setExpanded] = useState();
    const handleChange = (panelN) => (event, isExpanded) => {
        event.stopPropagation();
        setExpanded(isExpanded ? panelN : null);
    };
    const logout = () => {
        AuthService.logout().then( () => {
            history.push('/login');
            /*setTimeout( function () {
                window.location.reload();
            }, 300 );*/
        });
    };
    const isExpanded = (path) => {
        return expanded === path;
    };
    const {com_name} = AuthService.company;

    const cardContentLink = (label, link = false, externalLink = false, permessi = false, index) => {
        if(link && (!permessi || localStorage.getItem('permessi_utente').includes(permessi))) {
            return (
                <Link component={RouterLink} to={link} color={"inherit"} key={index}>
                    <ListItem button>
                        <ListItemText primary={label}/>
                    </ListItem>
                </Link>
            )
        }
        if(externalLink && (!permessi || localStorage.getItem('permessi_utente').includes(permessi))) {
            return (
                <Link href={`${externalLink}?uuid=${AuthService.user.utente.uuid}&env=${ENV}`} target="_blank"
                      color={"inherit"} key={index}>
                    <ListItem button>
                        <ListItemText primary={label}/>
                    </ListItem>
                </Link>
            )
        }
        return ''
    }

    const renderSpecificExportGrid = () => {
        if (localStorage.getItem('permessi_utente').includes('export.dati.flussi') || localStorage.getItem('permessi_utente').includes('odv.referente')) {
            return (
                <ListItem button onClick={() => {
                    window.open(`s231-excel.php?env=${ENV}&elab=funzioni-flussi&companyID=${AuthService.company.uuid}`);
                }}>
                    <ListItemText primary={'Funzioni - Referenti – Flussi OdV'}/>
                </ListItem>
            )
        }
    }

    // const handleDrawerMenu = useCallback(() => {
    //     SITE_MAP.reduce(({expansion}) => {
    //         if (expansion) {
    //             const open = expansion.find(({path}) => pathname.search(path) !== -1);
    //             if (!open) {
    //                 setExpanded(null);
    //             } else if (expanded !== open.path) {
    //                 setExpanded(open.path);
    //             }
    //         }
    //         return expansion;
    //     });
    // },[pathname])
    // useEffect(() => {
    //     handleDrawerMenu()
    // },[handleDrawerMenu]);
    return (
        <SwipeableDrawer
            open={open} variant={"temporary"}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}>
            <Grid container direction={"column"}
                  className={classes.menu} role="presentation" onClick={() => toggleDrawer(false)}
                  onKeyDown={() => toggleDrawer(false)} spacing={2} style={{margin: 0}} wrap={"nowrap"}>
                <Grid item container direction={"column"} alignContent={"center"}>
                    <Grid item>
                        {logo && <img src={logo} alt={'valore24 logo'}
                                      height={'40px'}
                                      style={{margin: '2rem auto 0'}}/>}
                        {!logo && <CircularProgress />}
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant={"subtitle2"} className={classes.whiteText}
                                align={"center"}>stai navigando come</Typography>
                </Grid>
                <Grid item>
                    <Divider component={'div'} className={classes.whiteDivider}>
                        {com_name ?
                        <Typography className={classes.dividerText} align={"center"}
                                    onClick={() => history.push('/profilo-utenza/')}>
                            {com_name}
                        </Typography> :
                            <Typography className={classes.dividerText} align={"center"}>
                                Admin
                            </Typography>}
                    </Divider>
                </Grid>
                <Grid item xs className={classes.scrollable} container direction={"column"} justify={"space-between"}
                      wrap={"nowrap"}>
                    <Grid item>
                        {MyHome.map(({label, path, expansion}, index) => (
                            <Grid container direction={"column"} wrap={"nowrap"}
                                  key={index}>
                                <Grid item>
                                    <Grid container direction={"column"}>
                                        {expansion.map(({label, path, items}, index) => (
                                            <Grid item key={index}>
                                                {items && items.length > 0 && <ExpansionPanel className={classes.expansionPanel}
                                                                expanded={isExpanded(path)}
                                                                onChange={handleChange(path)}>
                                                    <ExpansionPanelSummary
                                                        expandIcon={<Icon
                                                            className={classes.whiteText}>expand_more</Icon>}
                                                        aria-controls="panel1bh-content"
                                                        id="panel1bh-header">
                                                        <Typography color={'inherit'}
                                                                    variant={"button"}>
                                                            {label}
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails>
                                                        <List component="nav" className={classes.list} disablePadding>
                                                            {items.map(
                                                                ({label, link, externalLink, permessi}, index) =>
                                                                cardContentLink(label, link, externalLink, permessi, index)
                                                            )}
                                                        </List>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>}
                                                {(!items || (items && items.length === 0)) &&
                                                <ExpansionPanelSummary
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header">
                                                    <Link component={RouterLink} to={path} color={"inherit"} key={index}>
                                                        <Typography className={classes.whiteText} color={'inherit'}
                                                                    variant={"button"}>
                                                            {label}
                                                        </Typography>
                                                    </Link>
                                                </ExpansionPanelSummary>}
                                            </Grid>
                                        ))}
                                        {!expansion.length &&
                                        <ExpansionPanelSummary id="panel1bh-header"
                                                               onClick={() => history.push(path)}>
                                            <Typography className={classes.whiteText} variant={"button"}>
                                                {label}
                                            </Typography>
                                        </ExpansionPanelSummary>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('exports')} onChange={handleChange('exports')}>
                                            <ExpansionPanelSummary
                                                expandIcon={<Icon
                                                    className={classes.whiteText}>expand_more</Icon>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header">
                                                <Typography color={'inherit'}
                                                            variant={"button"}>
                                                    {'Exports'}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <List component="nav" className={classes.list} disablePadding>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText primary={'Reati 231'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-aree&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText primary={'Aree di rischio – Reati 231'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-protocolli&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText primary={'Aree di rischio – Protocolli'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-indicatori&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText primary={'Aree di rischio – Indicatori comportamentali'}/>
                                                    </ListItem>
                                                    <ListItem button onClick={() => { window.open(`/s231-excel.php?env=${ENV}&elab=reati-flussi&companyID=${AuthService.company.uuid}`); } }>
                                                        <ListItemText primary={'Aree di rischio – Flussi OdV'}/>
                                                    </ListItem>
                                                    {renderSpecificExportGrid()}
                                                </List>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                        <ExpansionPanel className={classes.expansionPanel} expanded={isExpanded('help')} onChange={handleChange('help')}>
                                            <ExpansionPanelSummary
                                                expandIcon={<Icon
                                                    className={classes.whiteText}>expand_more</Icon>}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header">
                                                <Typography color={'inherit'}
                                                            variant={"button"}>
                                                    {'Help'}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <List component="nav" className={classes.list} disablePadding>
                                                    <Link component={RouterLink} to={'/faq/richiesta-supporto'} color={"inherit"}>
                                                        <ListItem button>
                                                            <ListItemText primary={'Richiesta di supporto'}/>
                                                        </ListItem>
                                                    </Link>
                                                    <Link href={`https://formazione-231.valore24.ilsole24ore.com/?uuid=${AuthService.user.utente.uuid}&env=${ENV}`} target="_blank" color={"inherit"} >
                                                        <ListItem button>
                                                            <ListItemText primary={'Prenota video call formazione'}/>
                                                        </ListItem>
                                                    </Link>
                                                </List>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid container direction={"column"} wrap={"nowrap"} >
                            <Grid item>
                                <Grid container direction={"column"}>
                                    <Grid item>
                                    <ExpansionPanelSummary id="panel1bh-header" onClick={() => history.push('/news')}>
                                        <Typography className={classes.whiteText} variant={"button"}>
                                            231 News e Approfondimenti
                                        </Typography>
                                    </ExpansionPanelSummary>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid> */}
                    </Grid>
                    <Grid item className={classes.lastSection}>
                        <Divider component={'div'} className={classes.whiteDivider}>
                            <Typography className={classes.dividerText} align={"center"}>
                                Account
                            </Typography>
                        </Divider>
                        <Grid container direction={"column"} spacing={2}
                              style={{padding: '0 24px', marginBottom: '2rem'}}>
                            {/*<Grid item>
                            <Typography variant={"button"} className={classes.whiteText}>Need help?</Typography>
                        </Grid>*/}
                            <Grid item container justify={"space-around"}>
                                {com_name && <Grid item>
                                     <Button onClick={() => history.push('/profilo')}>
                                        <Icon className={classes.whiteText}
                                              style={{marginRight: '1rem'}}>settings</Icon>
                                        <Typography className={classes.whiteText}>
                                            profilo
                                        </Typography>
                                    </Button>
                                </Grid>}
                                <Grid item>
                                    <Button onClick={logout}>
                                        <Icon color={"error"} style={{marginRight: '1rem'}}>power_settings_new</Icon>
                                        <Typography className={classes.whiteText}>
                                            logout
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SwipeableDrawer>
    );
};

DrawerComponent.defaultProps = {
    open: false,
    toggleDrawer: () => console.warn('Missing toggleDrawer function')
};

DrawerComponent.propTypes = {
    toggleDrawer: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default withRouter(DrawerComponent);
