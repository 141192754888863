import React, {useEffect, useState} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import * as yup from "yup";
import {FlowCollectionApi, PeriodiApi} from '../../../services/api.service';
import Grid from "@material-ui/core/Grid";
import {KeyboardDatePicker} from '@material-ui/pickers';
import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";
import "moment/locale/it";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

moment.locale('it');

const NewFlowCollectionSchema = yup.object().shape({
    rac_start: yup.string().required('Inserisci data'),
    periodo_uuid: yup.string().required('Seleziona periodo'),
    rac_anno: yup.number().required('Inserire anno')
});


const Periodi = new PeriodiApi();
const FlowCollection = new FlowCollectionApi();
const FlowCollectionComponent = ({dispatch, history}) => {
    const [loading, setLoading] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [flows, setFlowCollection] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [openWarning, setOpenWarning] = useState(false);
    const [formData, setFormData] = useState(null)

    const showWarningDialog = (data) => {
        setFormData(data)
        setOpenWarning(true);
    };

    const closeWarningDialog = () => {
        setFormData(null)
        setOpenWarning(false);
    };

    const getDate = () => {
        let baseDate = new Date();
        let t = baseDate.toLocaleDateString('it-IT');
        return t;
    }

    useEffect(() => {
        const fetchSources = async () => {
            setApiLoading(true);
            const {raccolte} = await FlowCollection.query();
            setFlowCollection(raccolte);
            const {periodi} = await Periodi.query();
            setPeriods(periodi);
            setApiLoading(false);
        };
        fetchSources();
    }, []);
    const newFlowCollectionForm = useForm({
        mode: 'onSubmit',
        validationSchema: NewFlowCollectionSchema,
        defaultValues: {
            rac_start: getDate(),
            rac_anno: new Date().getFullYear()
        }
    });
    const forceRequired = (value) => {
        return (value === undefined || value === '') ? 'campo obbligatorio' : true;
    };

    const submitForm = async (data) => {
        try {console.log(data);
            setLoading(true);
            await FlowCollection.create(data);
            dispatch(handleToast({
                message: 'Nuova raccolta creata con successo'
            }));
            setLoading(false);
            setFormData(null)
            setOpenWarning(false);
            history.push('/flussi-OdV/raccolta-flussi');
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({message}));
        }
    }

    const onSubmit = (data) => {
        const found = flows.some(f => f.rac_close && f.rac_anno===data.rac_anno && f.periodi.uuid===data.periodo_uuid);
        if(found){
            showWarningDialog(data)
        }else{
            submitForm(data)
        }
    };

    if (apiLoading) {
        return (
            <Grid container justify={"center"}>
                <Grid item>
                    <CircularProgress/>
                </Grid>
            </Grid>
        )
    }
    return (
        <FormContext {...newFlowCollectionForm}>
            <form onSubmit={newFlowCollectionForm.handleSubmit(onSubmit)}>
                <Grid container alignItems="center" justify="space-around" spacing={4}>
                    <Grid item xs>
                        <KeyboardDatePicker
                            disableToolbar
                            fullWidth
                            variant="inline"
                            format="DD/MM/YYYY"
                            id="date-picker-inline"
                            label="Data di avvio"
                            InputLabelProps={{ shrink: true }}
                            inputRef={newFlowCollectionForm.register({name: 'rac_start'})}
                            value={newFlowCollectionForm.watch('rac_start') || getDate()}
                            onChange={(date) => newFlowCollectionForm.setValue('rac_start', date)}
                            KeyboardButtonProps={{'aria-label': 'Cambia data',}}
                            error={!!newFlowCollectionForm.errors['rac_start']}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            select
                            label="Periodo di riferimento"
                            fullWidth
                            value={newFlowCollectionForm.watch('periodo_uuid') || ''}
                            inputRef={newFlowCollectionForm.register({name: 'periodo_uuid'}, {validate: forceRequired})}
                            onChange={(e) => newFlowCollectionForm.setValue('periodo_uuid', e.target.value)}
                            error={!!newFlowCollectionForm.errors['periodo_uuid']}>
                            {periods.map(({per_periodo, uuid}) => <MenuItem value={uuid}
                                                                            key={uuid}>{per_periodo}</MenuItem>)}
                            {!periods.length &&
                            <MenuItem alignItems={"center"}>Nessuna categoria disponibile</MenuItem>}
                        </TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            label={"Anno"}
                            defaultValue={newFlowCollectionForm.watch('rac_anno') || ''}
                            inputRef={newFlowCollectionForm.register({name: 'rac_anno'})}
                            onChange={(e) => newFlowCollectionForm.setValue('rac_anno', e.target.value)}
                            error={!!newFlowCollectionForm.errors['rac_anno']}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify={"center"} spacing={4} style={{margin: '2rem 0'}}>
                            <Grid item>
                                <Button type={"submit"} onClick={() => history.push('/flussi-OdV/raccolta-flussi')}>
                                    Torna alla lista
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button type={"submit"} variant="contained" color="primary">
                                    {!loading && 'Avvia nuova raccolta flussi'}
                                    {loading && <CircularProgress size={24}/>}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
            <Dialog
                open={openWarning}
                onClose={closeWarningDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Messaggio Importante</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Attenzione, esiste già una raccolta per il periodo indicato, se si prosegue verranno inviate nuovamente ai referenti le richieste di raccolta dati  per lo stesso periodo
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeWarningDialog} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={() => submitForm(formData)} color="primary" autoFocus>
                        Conferma e procedi
                    </Button>
                </DialogActions>
            </Dialog>
        </FormContext>
    )
};

FlowCollectionComponent.propTypes = {};

export default withRouter(connect()(FlowCollectionComponent))
