import {handleModal} from "../../../redux/actions/modal.action";
import {handleToast} from "../../../redux/actions";
import useForm, {FormContext} from "react-hook-form";
import Grid from "@material-ui/core/Grid";
import GenericTextFieldsForm from "../../hooks-forms/generic-text-fields/generic-text-fields.form";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import React, {useState} from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {fetchAPI} from "../../../redux/actions/fetch-api.action";
import { DelegatedAdminApi } from "../../../services/api.service";

const delegatedAdminAPI = new DelegatedAdminApi();
const DelegatedAdminComponent = ({dispatch, mode, admin}) => {
    if ( admin !== undefined && admin.account !== undefined ) {
        admin.email = admin.account.acc_email;
        admin.phone = admin.account.acc_mobile;
    }
    const [loading, setLoading] = useState(false);
    const disabled = mode === 'read' || mode === 'delete';

    const addDelegatedAdminInputs = [
        {
            name: 'email',
            required: 'campo obbligatorio',
            label: 'Email',
            placeholder: 'Inserisci email',
            width: 12,
            margin: 'normal',
            type: 'email',
            value: 'ciao',
            disabled,
            validate: (value) => ((!value && (delegatedAdminForm.watch('email') || delegatedAdminForm.watch('ref_referente')))  || (!value && !delegatedAdminForm.watch('ref_email') && !delegatedAdminForm.watch('ref_telefono'))) ? 'Email richiesta' : true
        },
    ];

    if (mode === 'create' || mode === 'update') {
        addDelegatedAdminInputs.push({
            name: 'username',
            required: 'campo obbligatorio',
            label: 'Username',
            placeholder: 'Inserisci nome',
            width: 12,
            margin: 'normal',
            disabled,
            validate: (value) => ((!value && (delegatedAdminForm.watch('email') || delegatedAdminForm.watch('ref_telefono'))) || (!value && !delegatedAdminForm.watch('ref_email') && !delegatedAdminForm.watch('ref_telefono'))) ? 'Nome richiesto' : true
        });

        addDelegatedAdminInputs.push({
            name: 'password',
            label: 'Password',
            required: 'campo obbligatorio',
            placeholder: 'Inserisci Password',
            width: 12,
            margin: 'normal',
            type: 'password',
            disabled,
            validate: (value) => ( (mode !=='delete' && mode !== 'update') && ((!value && (delegatedAdminForm.watch('ref_email') || delegatedAdminForm.watch('ref_referente')))  || (!value && !delegatedAdminForm.watch('ref_email') && !delegatedAdminForm.watch('ref_telefono')))) ? 'Password richiesta' : true
        });

        addDelegatedAdminInputs.push({
            name: 'password_confirmation',
            label: 'Conferma password',
            required: 'campo obbligatorio',
            placeholder: 'Conferma la password',
            width: 12,
            margin: 'normal',
            type: 'password',
            disabled,
            validate: (value) => ( (mode !=='delete' && mode !== 'update') && ((!value && (delegatedAdminForm.watch('ref_email') || delegatedAdminForm.watch('ref_referente')))  || (!value && !delegatedAdminForm.watch('ref_email') && !delegatedAdminForm.watch('ref_telefono')))) ? 'Password confermata richiesta' : true
        });
    }
    
    const delegatedAdminForm = useForm({
        mode: 'onSubmit',
        defaultValues: mode !== 'create' ? {
            ...admin,
        } : {}
    });
    const buttonLabel = () => {
        let label;
        switch (mode) {
            case 'create':
                label = 'Salva';
                break;
            case 'update':
                label = 'Modifica';
                break;
            case 'delete':
                label = 'Elimina';
                break;
            case 'associate':
                label = 'Associa';
                break;
            default:
                label = 'Invalid saving mode';
                break;
        }
        return <Typography variant={"button"} color={mode === 'delete' ? 'error' : 'primary'}>{label}</Typography>
    };
    const onDelegatedAdminSave  = async (data) => {
        try {
            setLoading(true);
            switch (mode) {
                case 'create':
                    await delegatedAdminAPI.storeDelegatedAdmin({
                        ...data,
                    });
                    dispatch(handleToast({
                        message: 'Utente Admin delegato creato'
                    }));
                    break;
                case 'update':
                    await delegatedAdminAPI.storeDelegatedAdmin({
                        id: admin.id,
                        ...data
                    });
                    dispatch(handleToast({
                        message: 'Utente Admin delegato modificato'
                    }));
                    break;
                case 'delete':
                    await delegatedAdminAPI.deleteDelegatedAdmin(admin.id);
                    dispatch(handleToast({
                        message: 'Utente Admin delegato eliminato'
                    }));
                    break;

                case 'associate':
                    await delegatedAdminAPI.storeDelegatedAdmin({
                        associate: true,
                        ...data,
                    });
                    dispatch(handleToast({
                        message: 'Utente associato come Admin delegato'
                    }));
                    break;
                default:
                    return dispatch(handleToast({
                        message: 'Invalid saving mode'
                    }));
            }
            setLoading(false);
            dispatch(fetchAPI());
            dispatch(handleModal({
                open: false
            }));
        } catch (message) {
            setLoading(false);
            dispatch(handleToast({
                message
            }));
        }
    };

    return (
        <FormContext {...delegatedAdminForm}>
            <form onSubmit={delegatedAdminForm.handleSubmit(onDelegatedAdminSave )}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GenericTextFieldsForm inputs={addDelegatedAdminInputs}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} fullWidth>
                            {!loading && buttonLabel()}
                            {loading && <CircularProgress size={24}/>}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </FormContext>
    )
};

DelegatedAdminComponent.defaultProps = {
    admin: {}
};
DelegatedAdminComponent.propTypes = {
    admin: PropTypes.object,
    mode: PropTypes.oneOf([
        'create',
        'read',
        'update',
        'delete'
    ]).isRequired
};
export default connect()(DelegatedAdminComponent);
