import {generateID, initComponentClass} from "../../index.utils";
import {MODE_VIEW_COMPILE} from "../../index.constants";

export class Section {
    constructor({gridName, gridCol, inputList} = {}, options = {}) {
        this.id = generateID(12);
        this.priority = 0;
        this.inputList = inputList ? inputList.map(({name, type, ...props}) => {
            return initComponentClass(name, options.viewMode, type, props);
        }) : [];
        this.gridName = {
            name: `grid-${generateID(9)}-name`,
            viewMode: options.viewMode || MODE_VIEW_COMPILE,
            placeholder: 'Es. Anagrafica utente',
            initialValue: gridName ? gridName.initialValue : '',
            width: 200
        };
        this.gridSizeSelect = {
            name: `grid-${generateID(9)}-col-number`,
            viewMode: options.viewMode || MODE_VIEW_COMPILE,
            label: 'Numero colonne',
            select: true,
            initialValue: gridCol ? gridCol.initialValue : '1',
            width: 100,
            options: [
                {
                    label: '1',
                    value: '1'
                },
                {
                    label: '2',
                    value: '2'
                },
                {
                    label: '3',
                    value: '3'
                }
            ]
        }
    }
}
