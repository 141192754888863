import React, {useEffect} from 'react';
import useForm from 'react-hook-form';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import AuthService from "../../services/auth.service";
import Link from "@material-ui/core/Link";
import {handleToast, saveUser} from "../../redux/actions";
import {connect} from "react-redux";
import {Link as RouteLink, withRouter} from "react-router-dom";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {GRC_SELECTED_COMPANY} from "../../redux/types";
import {ConfigApi} from "../../services/api.service";
import {saveCompanyId} from "../../redux/actions/auth.action";


const LoginPage = ({dispatch, history, location}) => {
    const configApi = new ConfigApi();
    const {register, handleSubmit, errors} = useForm();
    const [loginLogo, setLoginLogo] = React.useState(null);

    useEffect(() => {
        const loadPalette = async () => {
            const configData = await configApi.setup();
            if ( configData?.logo_login ) {
                setLoginLogo(configData?.logo_login);
            }
        };
        loadPalette();
    }, []);

    const onSubmit = async (credentials) => {
        try {
            const user = await AuthService.login(credentials);
            dispatch(saveUser(user));
            //if ( localStorage.getItem(GRC_SELECTED_COMPANY) !== null ) {
            if ( localStorage.getItem('permessi_utente').indexOf('risk.referente') > 0 ) {
                let company = localStorage.getItem(GRC_SELECTED_COMPANY);
                dispatch(saveCompanyId(company.id, company.com_name));
                history.push('/risk-assessment/elenco-valutazioni');
            } else {
                if (localStorage.getItem('permessi_utente').indexOf('odv.referente') > 0 ) {
                    let company = localStorage.getItem(GRC_SELECTED_COMPANY);
                    dispatch(saveCompanyId(company.id, company.com_name));
                    history.push('/homepage');
                } else {
                    history.push('/');
                }
            }
        } catch (message) {
            dispatch(handleToast({
                message
            }));
        }
    };
    return (
        <Container maxWidth={"sm"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={"column"} justify={"center"} spacing={4}>
                    <Grid item>
                        {loginLogo && <img src={loginLogo} alt={'Logo'} width={'auto'}
                             height={'60px'} style={{margin: '0 auto', display: 'block'}}/>}
                    </Grid>
                    {location.search.search('success=ok') !== -1 &&
                    <Grid item>
                        <Card style={{background: green[400], padding: '1rem'}}>
                            <Typography variant="h6" align={"center"} style={{color: 'white'}}>
                                Account attivato con successo
                            </Typography>
                        </Card>
                    </Grid>}
                    {location.search.search('success=ko') !== -1 &&
                    <Grid item>
                        <Card style={{background: red[400], padding: '1rem'}}>
                            <Typography variant="h6" align={"center"} style={{color: 'white'}}>
                                Attivazione account fallita!
                            </Typography>
                        </Card>
                    </Grid>}
                    {location.search.search('success=verified') !== -1 &&
                    <Grid item>
                        <Card style={{background: grey[200], padding: '1rem'}}>
                            <Typography variant="h6" align={"center"} color={"textSecondary"}>
                                Account già attivato!
                            </Typography>
                        </Card>
                    </Grid>}
                    <Grid item>
                        <Grid container direction={"column"}>
                            <Grid item>
                                <TextField type="text" label="P.Iva o Cod. Fiscale" name="username" margin="normal"
                                           error={!!errors.username}
                                           helperText={errors.username ? 'Campo obbligatorio' : 'In caso di possesso sia della p.iva che del c.f. utilizzare la p.iva per effettuare il login'}
                                           inputRef={register({required: true})} fullWidth={true}/>
                            </Grid>
                            <Grid item>
                                <TextField label="Password" name="password" type="password" margin="normal"
                                           error={!!errors.password}
                                           helperText={errors.password ? 'Campo obbligatorio' : ''}
                                           inputRef={register({required: true})} fullWidth={true}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button type="submit" color={"primary"} variant={"contained"} fullWidth>
                            login
                        </Button>
                    </Grid>
                    <Grid item container justify={"center"}>
                        <Grid item>
                            <Link color={"primary"} align={"center"} component={RouteLink}
                                  to={'/reset-password'}>
                                hai dimenticato la password?
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default connect()(withRouter(LoginPage));
