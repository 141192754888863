import React from 'react';
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {DropzoneArea} from "material-ui-dropzone";
import {FormHelperText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {FlowTrackApi} from "../../../../services/api.service";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {handleToast} from "../../../../redux/actions";

const FlowTrack = new FlowTrackApi();
const FileInputCompile = (props) => {
  const {
    label,
    name,
    setFile,
    file,
    helperText,
    options,
    readOnly,
    required,
    initialValue,
    match: {
      params: { uuid },
    },
    location: { search },
    dispatch,
  } = props;
  const { register, setValue, watch, errors } = useFormContext();
  const forceRequired = (value) => {
    return required && (value === undefined || value === "")
      ? `${required}`
      : true;
  };

  const fileUpload = (files) => {
    if (files[0]) {
      const fileName = `${Date.now()}_${files[0].name}`;
      setFile(new File([files[0]], fileName));
      setValue(name, fileName);
    }
  };

  const getFileName = () => {
    if (!initialValue) {
      return "file senza nome";
    }

    let fileName = initialValue.split("_");
    fileName.splice(0, 1);
    return fileName.join("_");
  };

  const getFileData = async () => {
    if(initialValue) {
      const response = await fetch(initialValue, {mode: 'no-cors'});
      const data = await response.blob();
      const metadata = {type: data.type};
      const filename = initialValue.replace(/\?.+/, '').split('/').pop();
      const ext = data.type.split('/').pop();
      //setFile(fullFile);
      return new File([data], `${filename}.${ext}`, metadata);
    }
  };

  const getFileUrl = () => {
    if ( initialValue ) {
      return initialValue;
    }

    return '';
  }

  const getTrackUuid = () => {
    let uuid;
    try {
      // extract track_uuid param from url
      uuid = search
        .replace("?", "")
        .split("&")
        .find((queryString) => queryString.split("=")[0] === "track_uuid")
        .split("=")[1];
    } catch (e) {
      console.error(e);
    }
    return uuid;
  };
  const downloadFile = async () => {
    try {
      const file = await FlowTrack.downloadFile(
        uuid || getTrackUuid(),
        initialValue
      );
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.download = getFileName();
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
      dispatch(
        handleToast({
          message: "File scaricato",
        })
      );
    } catch (message) {
      dispatch(
        handleToast({
          message,
        })
      );
    }
  };

  return (
    <FormControl component="fieldset" fullWidth={true}>
      {label && !readOnly && <FormLabel component="legend">{label}</FormLabel>}
      {readOnly && !initialValue && (
        <FormLabel component="legend">Documento non caricato</FormLabel>
      )}
      {readOnly && initialValue && (
        <FormLabel component="legend">Documento caricato</FormLabel>
      )}
      {readOnly && initialValue && (
        <Grid
          container
          justify={"center"}
          spacing={2}
          style={{ marginTop: "1rem" }}
        >
          <Grid item xs={12}>
            <Typography variant={"subtitle1"} align={"center"}>
              {getFileName()}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant={"outlined"}
              color={"secondary"}
              onClick={downloadFile}
            >
              scarica
            </Button>
          </Grid>
        </Grid>
      )}
      {!readOnly && (
        <DropzoneArea
          onChange={fileUpload}
          filesLimit={1}
          maxFileSize={100000000}
          acceptedFiles={options
            .filter(({ checked }) => checked === true || checked === "true")
            .map(({ value }) => value)}
          dropzoneText={
            !initialValue ? "Trascina il file o clicca qui" : getFileName()
          }
          dropzoneClass={!file ? "custom-dropzone" : "custom-dropzone-no-text"}
          showFileNames={false}
          initialFiles={initialValue ? [getFileUrl()] : []}
          showPreviewsInDropzone={true}
        />
      )}
      <input
        type="hidden"
        value={watch(name) || initialValue}
        ref={register({ name }, { validate: forceRequired })}
      />
      {!readOnly && helperText && <FormHelperText>{helperText}</FormHelperText>}
      {errors[name] && (
        <FormHelperText>
          <Typography color={"error"}>{errors[name].message}</Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};

FileInputCompile.defaultProps = {
  disabled: false,
  label: "",
  placeholder: "placeholder",
  required: false,
  initialValue: "",
  labelPlacement: "end",
  width: "auto",
  readOnly: false,
  multiline: false,
  select: false,
  margin: "none",
  options: [],
  type: "text",
  variant: "standard",
};

FileInputCompile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ),
};

export default connect()(withRouter(FileInputCompile));
