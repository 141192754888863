import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Container from "@material-ui/core/Container";
import { connect } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { withRouter } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { CompanyApi, CompanyApiNew } from "../../services/api.service";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { handleToast } from "../../redux/actions";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import { saveCompanyId } from "../../redux/actions/auth.action";
import {GRC_USER} from "../../redux/types";
import {ENV} from "../../constant";
import Link from "@material-ui/core/Link";

const Company = new CompanyApi();
const CompanyNew = new CompanyApiNew();
const useStyles = makeStyles((theme) => ({
  listItem: {
    borderBottom: "1px solid rgba(0,0,0, .12)",
    padding: theme.spacing(3),
  },
}));

const SelectCompanyPage = ({ dispatch, history }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selected, setCompany] = useState();
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        setLoading(true);
        const companies = await Company.query();
        AuthService.setCompanies(companies);
        setCompanies(companies);
      } catch (message) {
        await AuthService.logout();
        history.push("/login");
        dispatch(handleToast({ message }));
      }
      setLoading(false);
    };
    fetchCompanies().then(r => {console.log(r)});
  }, []);
  const handleSelectCompany = (index) => {
    setCompany(companies[index]);
  };
  const isSelected = (index) => {
    return companies[index] === selected;
  };
  const select = async (company) => {
    AuthService.company = company;
    const { id, com_name } = await CompanyNew.getFormUid(company.uuid);
    dispatch(saveCompanyId(id, com_name));
    history.push("/homepage");
  };
  const logout = async () => {
    await AuthService.logout();
    history.push("/login");
    return true;
  };

  if (localStorage.getItem('permessi_utente') && localStorage.getItem('permessi_utente').includes('risk.referente')) {
    history.push("/risk-assessment/elenco-valutazioni");
  }

  return (
    <Container maxWidth={"sm"} style={{ marginTop: "2rem" }}>
      <Grid container direction={"column"} spacing={4}>
        <Grid item>
          <Typography variant={"h4"} align={"center"}>
            Lista Società
          </Typography>
          <Typography variant={"subtitle1"} align={"center"}>
            {AuthService.user && AuthService.user.utente.accounts.acc_name}
          </Typography>
        </Grid>
        <Grid item>
          <List>
            {companies.map((company, index) => (
              <ListItem
                key={index}
                button
                selected={isSelected(index)}
                onMouseOver={(e) => handleSelectCompany(index)}
                onClick={() => select(company)}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <Icon
                    color={isSelected(index) ? "primary" : "disabled"}
                    fontSize={"large"}
                  >
                    account_balance
                  </Icon>
                </ListItemIcon>
                <ListItemText primary={company.com_name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="settings"
                    onClick={() => {
                      AuthService.company = company;
                      history.push(`/profilo-utenza`);
                    }}
                  >
                    <Icon>settings</Icon>
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {!loading && !companies.length && (
              <ListItem>
                <Typography align={"center"} style={{ width: "100%" }}>
                  Nessuna società ancora creata
                </Typography>
              </ListItem>
            )}
            {loading && (
              <ListItem>
                <Grid container justify={"center"}>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid item style={{ marginBottom: 50 }}>
          <Grid
            container
            direction={"column"}
            alignItems={"center"}
            spacing={4}
          >
            <Grid item >
                        <Button onClick={() => history.push("/aggiungi-utenza")} color={"primary"} variant={"contained"} style={{ margin: 5 }}>
                        aggiungi società
                        </Button>
                        <Button  color={"primary"} variant={"outlined"} style={{ margin: 5 }}>
                        <Link href={`https://formazione-231.valore24.ilsole24ore.com/?uuid=${AuthService.user.utente.uuid}&env=${ENV}`} target="_blank" color={"inherit"} >
                  {'Prenota Video Call Formazione'}
                </Link>
                        </Button>
            </Grid>

            {/* <Grid item>
              <Fab
                onClick={() => history.push("/aggiungi-utenza")}
                color={"secondary"}
                variant={"extended"}
              >
                aggiungi società
              </Fab>
              <Fab
                  color={"secondary"}
                  variant={"extended"}
              >
                <Link href={`https://formazione-231.valore24.ilsole24ore.com/?uuid=${AuthService.user.utente.uuid}&env=${ENV}`} target="_blank" color={"inherit"} >
                  {'Prenota Video Call Formazione'}
                </Link>
              </Fab>

            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

SelectCompanyPage.defaultProps = {
  companies: [],
};

export default connect()(withRouter(SelectCompanyPage));
